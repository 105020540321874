import revive_payload_client_4sVQNw7RlN from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_2ruDq4uEtU from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_yVLowv6hDl from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import auth_vT9JWWT9pN from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/plugins/auth.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_2ruDq4uEtU,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  plugin_client_OVoKJro5pc,
  auth_vT9JWWT9pN
]
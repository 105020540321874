import { default as aboutGlasLB6gfUMeta } from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/about.vue?macro=true";
import { default as adminGXwpmXWQnmMeta } from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/admin.vue?macro=true";
import { default as forgotPasswordxmiTSvYzoRMeta } from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/auth/forgotPassword.vue?macro=true";
import { default as loginHkiML84yuXMeta } from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/auth/login.vue?macro=true";
import { default as resetPasswordvd6DrTXfMSMeta } from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/auth/resetPassword.vue?macro=true";
import { default as signUpbcVr6EGZEbMeta } from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/auth/signUp.vue?macro=true";
import { default as createNetworkcoiUCmvPqoMeta } from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/createNetwork.vue?macro=true";
import { default as unauthorizedAmvW5eaCLvMeta } from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/errors/unauthorized.vue?macro=true";
import { default as indexHvQ9rc7wWeMeta } from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/index.vue?macro=true";
import { default as MakeNetworktt2bJ4UJlDMeta } from "C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/MakeNetwork.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutGlasLB6gfUMeta || {},
    component: () => import("C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/about.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/admin.vue")
  },
  {
    name: "auth-forgotPassword",
    path: "/auth/forgotPassword",
    component: () => import("C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/auth/forgotPassword.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/auth/login.vue")
  },
  {
    name: "auth-resetPassword",
    path: "/auth/resetPassword",
    meta: resetPasswordvd6DrTXfMSMeta || {},
    component: () => import("C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/auth/resetPassword.vue")
  },
  {
    name: "auth-signUp",
    path: "/auth/signUp",
    component: () => import("C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/auth/signUp.vue")
  },
  {
    name: "createNetwork",
    path: "/createNetwork",
    component: () => import("C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/createNetwork.vue")
  },
  {
    name: "errors-unauthorized",
    path: "/errors/unauthorized",
    component: () => import("C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/errors/unauthorized.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/index.vue")
  },
  {
    name: "MakeNetwork",
    path: "/MakeNetwork",
    component: () => import("C:/inetpub/wwwroot/anc-nuxt/user-management-shadcn/pages/MakeNetwork.vue")
  }
]